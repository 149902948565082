// Custom header JS here.
document.addEventListener("DOMContentLoaded", function () {
    const header = document.querySelector(".site-header");
    if (header) {
        gsap.from(header, {
            opacity: 0,
            marginTop: -30,
            duration: 1.5,
            ease: "power2.out"
        });
    }
});