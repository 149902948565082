// Fade-In-Up animation
document.addEventListener("DOMContentLoaded", function () {
    const animationConfig = {
        up: {y: 30},
        down: {y: -30},
        left: {x: 30},
        right: {x: -30}
    };

    function animateElements(selector, direction, noDelay = false) {
        const elements = document.querySelectorAll(selector);
        if (elements.length > 0) {
            elements.forEach(function (element) {
                const animationSettings = {
                    opacity: 0,
                    ...animationConfig[direction], // Spread the direction config
                    duration: 1.5,
                    ease: "power2.out",
                };

                if (noDelay) {
                    gsap.from(element, animationSettings);
                } else {
                    gsap.from(element, {
                        ...animationSettings,
                        scrollTrigger: {
                            trigger: element,
                            start: "top 80%", // Start the animation when the top of the element is 80% from the top of the viewport
                            toggleActions: "play none none none" // Play the animation once when the element comes into view
                        },
                    });
                }
            });
        }
    }

    // Animate elements with different directions
    animateElements(".has-fade-in-up", "up");
    animateElements(".has-fade-in-down", "down");
    animateElements(".has-fade-in-left", "left");
    animateElements(".has-fade-in-right", "right");

    // Animate elements without delay on page load
    animateElements(".has-fade-in-up-no-delay", "up", true);
    animateElements(".has-fade-in-down-no-delay", "down", true);
    animateElements(".has-fade-in-left-no-delay", "left", true);
    animateElements(".has-fade-in-right-no-delay", "right", true);
});