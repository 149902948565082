// Ensure webpack processes the main scss file.
import '../scss/main.scss';
// Import core scripts
import './main/core/accessibility.js';
// Import our layout scripts
import './main/layout/header.js';
//import './main/layout/footer.js';
// Import UI scripts
// import './main/ui/buttons.js';
// import './main/ui/links.js';
// Import animation scripts
import './main/animations/fade-ins.js';

// Register plugins
//gsap.registerPlugin(ScrollTrigger, SplitText, ScrollSmoother);
gsap.registerPlugin(ScrollTrigger);


// import './main/animations/scroll-highlighter.js';
// import './main/animations/case-study-titles.js';
